// ---------------------------------------------------
// Ionic dark theme
// ---------------------------------------------------
body.dark {

  color-scheme: dark;

	--ion-color-primary: #e5b118;
	--ion-color-primary-rgb: 229,177,24;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #ca9c15;
	--ion-color-primary-tint: #e8b92f;

	--ion-color-secondary: #0079c6;
	--ion-color-secondary-rgb: 0,121,198;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #006aae;
	--ion-color-secondary-tint: #1a86cc;

  --ion-color-tertiary: #e5b118;
	--ion-color-tertiary-rgb: 229,177,24;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #ca9c15;
	--ion-color-tertiary-tint: #e8b92f;

	--ion-color-success: #72cc66;
	--ion-color-success-rgb: 114,204,102;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #64b45a;
	--ion-color-success-tint: #80d175;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #ec4646;
	--ion-color-danger-rgb: 236,70,70;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #d03e3e;
	--ion-color-danger-tint: #ee5959;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222222;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e1e1e;
  --ion-color-light-tint: #3a3a3a;

  /** Subscription plans colors **/
  --silver-plan-color: #565656;
  --silver-plan-color-contrast: #ffffff;
  --gold-plan-color: #6c6142;
  --gold-plan-color-contrast: #ffffff;
  --entreprise-plan-color: #43677a;
  --entreprise-plan-color-contrast: #ffffff;

  /** black **/
  --ion-color-black: #ffffff;
  --ion-color-black-rgb: 255,255,255;
  --ion-color-black-contrast: #000000;
  --ion-color-black-contrast-rgb: 0,0,0;
  --ion-color-black-shade: #e0e0e0;
  --ion-color-black-tint: #ffffff;

  /** white **/
  --ion-color-white: #000000;
  --ion-color-white-rgb: 0,0,0;
  --ion-color-white-contrast: #ffffff;
  --ion-color-white-contrast-rgb: 255,255,255;
  --ion-color-white-shade: #000000;
  --ion-color-white-tint: #1a1a1a;

  /** lighter **/
  --ion-color-lighter: #0c0c0c;
  --ion-color-lighter-rgb: 12,12,12;
  --ion-color-lighter-contrast: #ffffff;
  --ion-color-lighter-contrast-rgb: 255,255,255;
  --ion-color-lighter-shade: #020202;
  --ion-color-lighter-tint: #0c0c0c;
  /** lighter2 **/
  --ion-color-lighter2: #070707;
  --ion-color-lighter2-rgb: 7,7,7;
  --ion-color-lighter2-contrast: #ffffff;
  --ion-color-lighter2-contrast-rgb: 255,255,255;
  --ion-color-lighter2-shade: #020202;
  --ion-color-lighter2-tint: #070707;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  --ion-background-color: #1c1c1d;
  --ion-background-color-rgb: 28, 28, 29;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #2a2a2a;

  --ion-card-background: #2a2a2a;
}

.ios body.dark ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
  --ion-item-background: #2a2a2a;
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #1c1c1d;
  --ion-background-color-rgb: 28, 28, 29;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #2a2a2a;

  --ion-toolbar-background: #000000;

  --ion-tab-bar-background: #1c1c1d;

  --ion-card-background: #2a2a2a;
}