// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  ion-grid.largeGrid {
    --ion-grid-width-xl: 1440px;
  }

  .h1l {
    margin-top: 20px;
    font-size: 26px;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.2;
  }

  --top-header-height: 80px;

  /* Set the font family of the entire app */
  --ion-font-family: "Roboto", "Helvetica Neue", sans-serif;

  /* Set the height of the select boxes */
  .alert-wrapper {
    max-height: 450px !important;
    .alert-checkbox-group,
    .alert-radio-group {
      max-height: 450px !important;
    }
    /* Fix the missing scroll bar */
    .alert-checkbox-group::-webkit-scrollbar,
    .alert-radio-group::-webkit-scrollbar {
      width: 1em;
      display: block !important;
    }
    .alert-button.colorDanger,
    ion-label.colorDanger {
      color: var(--ion-color-danger);
    }
    .alert-button.colorSuccess,
    ion-label.colorSuccess {
      color: var(--ion-color-success);
    }
    .alert-button.colorWarning,
    ion-label.colorWarning {
      color: var(--ion-color-warning);
    }
    .alert-button.colorSecondary,
    ion-label.colorSecondary {
      color: var(--ion-color-secondary);
    }
  }

  ion-toolbar.page-toolbar {
    --background: var(--ion-toolbar-background);
    ion-title {
      font-size: 20px;
    }
  }

  .infoIconTooltip {
    font-size: 14px !important;
    background: var(--ion-color-primary) !important;
    color: var(--ion-color-primary-contrast) !important;
    text-align: justify !important;
    &.secondary {
      background: var(--ion-color-secondary) !important;
      color: var(--ion-color-secondary-contrast) !important;
    }
  }

  #profileMenuPopover::part(content) {
    width: 280px;
  }

  .fullscreen-modal {
    --width:100% !important;
    --height:100% !important;
  }

  .profileMenuDiv {
    --width: 300px;
    --min-width: 300px;
    --max-width: 300px;
  }

  #cancelButton {
    --padding-start: 0;
    --padding-end: 0;
    ion-back-button {
      height: 100%;
      text-transform: none;
    }
  }

  ion-label.input-error {
    font-size: 12px;
  }

  ion-icon.mt-2 {
    margin-top: -2px;
  }

  ion-segment[mode="ios"] {
    background-color: var(--ion-color-lighter);
    border-color: var(--ion-color-lighter-shade) !important;
    border-radius: 100px;
    ion-label {
      color: var(--ion-color-lighter-contrast);
    }
    ion-segment-button::part(indicator-background) {
      background-color: var(--ion-color-tertiary);
      border-radius: 100px;
    }
    ion-segment-button.segment-button-checked {
      ion-label {
        color: var(--ion-color-tertiary-contrast);
      }
    }
  }

  ion-searchbar {
    --background: var(--ion-item-background);
    background: var(--ion-item-background);
    border-bottom: 1px solid var(--ion-border-color);
  }

  ion-card {
    box-shadow: none;
    border-radius: 0;
  }

  ion-popover.searchFilterPopover {
    --width: 400px;
  }
  ion-fab-button.noShadow {
    --box-shadow: none;
  }

  ion-select{
    max-width: 100% !important;
  }

  ion-button {
    text-transform: none;
    &[type=submit] {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
    }
    --border-radius: 0;
    --box-shadow: none;
  }

  .requiredStar {
    color: var(--ion-color-danger);
  }

  .cutText {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .customLink {
    color: var(--ion-color-primary);
    outline: none;
    &:hover {
      cursor: pointer;
      color: var(--ion-color-primary-shade);
    }
    &:focus {
      outline: none;
    }
    &.underlined:hover {
      text-decoration: underline;
    }
    &.secondary {
      color: var(--ion-color-secondary);
    }
    &.tertiary {
      color: var(--ion-color-tertiary);
    }
    &.white {
      color: var(--ion-color-white);
      &:hover {
        color: var(--ion-color-white-shade);
      }
    }
    &.contrast {
      color: var(--ion-color-contrast);
    }
  }

  .full-width {
    width: 100%;
  }
  .half-width {
    width: 50%;
  }
  .full-height {
    height: 100%;
  }

  .smoothBorder {
    border-radius: 10px;
  }

  .animatedBgColor {
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
  }

  .markerPopup {
    .leaflet-popup-content-wrapper {
      background-color: var(--ion-color-alwaysDark);
      color: var(--ion-text-color);
      padding: 0 !important;
      &:hover { cursor: pointer; }
      .leaflet-popup-content {
        margin: 0 !important;
        .content {
          margin: 0;
          padding: 0;
          img {
            border-radius: 10px 10px 0px 0px;
            user-drag: none;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -ms-user-select: none;
          }
          div {
            margin: 0px 10px;
            padding: 10px 0px;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
    .leaflet-popup-tip {
      background-color: var(--ion-color-alwaysDark);
    }
  }
  .mapPriceTag {
    display: flex;
    max-width: 110px;
    min-width: 80px;
    margin-top: -20px !important;
    margin-left: -40px !important;
    height: inherit !important;
    width: inherit !important;
    padding: 4px 8px;
		background-color: var(--ion-item-background);
		border: 1px solid var(--ion-border-color);
    border-radius: 50px;
    -webkit-box-shadow: 0px 8px 10px -10px #000000; 
    box-shadow: 0px 8px 10px -10px #000000;

    &.highlighted {
      background-color: var(--ion-color-alwaysDark);
      border: 1px solid var(--ion-color-alwaysDark-shade);
      color: var(--ion-color-tertiary);
    }

	}
  .mapLocker {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    position: absolute;
    z-index: 1000;
    right: 0;
    font-size: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.shake-top {
      -webkit-animation: shake-top 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
      animation: shake-top 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    }

    &:hover {
      cursor: pointer;
    }
  }

  /** Modal with the style of an alert **/
  .alertStyle {
    .modal-wrapper {
      width: 300px;
      height: 400px;
      max-width: 90%;
      max-height: 90%;
    }
  }

  /** Modal for the rule maker **/
  .ruleMaker {
    .modal-wrapper {
      height: 300px;
    }
  }

  /** Margin rules **/
  .mx-auto { margin-left: auto; margin-right: auto; }
  .ml-auto { margin-left: auto; }
  .mr-auto { margin-right: auto; }

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** black **/
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0,0,0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255,255,255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  /** lighter **/
  --ion-color-lighter: #efefef;
  --ion-color-lighter-rgb: 237,237,237;
  --ion-color-lighter-contrast: #000000;
  --ion-color-lighter-contrast-rgb: 0,0,0;
  --ion-color-lighter-shade: #e0e0e0;
  --ion-color-lighter-tint: #efefef;
  /** lighter2 **/
  --ion-color-lighter2: #fafafa;
  --ion-color-lighter2-rgb: 250,250,250;
  --ion-color-lighter2-contrast: #000000;
  --ion-color-lighter2-contrast-rgb: 0,0,0;
  --ion-color-lighter2-shade: #e0e0e0;
  --ion-color-lighter2-tint: #fafafa;

  /** red **/
  --ion-color-red: #d52b2b;
  --ion-color-red-rgb: 213,43,43;
  --ion-color-red-contrast: #ffffff;
  --ion-color-red-contrast-rgb: 255,255,255;
  --ion-color-red-shade: #bb2626;
  --ion-color-red-tint: #d94040;

  /** yellow **/
  --ion-color-yellow: #ffc107;
  --ion-color-yellow-rgb: 255,193,7;
  --ion-color-yellow-contrast: #000000;
  --ion-color-yellow-contrast-rgb: 0,0,0;
  --ion-color-yellow-shade: #e0aa06;
  --ion-color-yellow-tint: #ffc720;

  /** google **/
  --ion-color-google: #4285f4;
	--ion-color-google-rgb: 66,133,244;
	--ion-color-google-contrast: #ffffff;
	--ion-color-google-contrast-rgb: 255,255,255;
	--ion-color-google-shade: #3a75d7;
	--ion-color-google-tint: #5591f5;

  /** facebook **/
  --ion-color-facebook: #4267b2;
  --ion-color-facebook-rgb: 66,103,178;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255,255,255;
  --ion-color-facebook-shade: #3a5b9d;
  --ion-color-facebook-tint: #5576ba;

  /** whats app **/
  --ion-color-whatsapp: #2acb49;
	--ion-color-whatsapp-rgb: 42,203,73;
	--ion-color-whatsapp-contrast: #ffffff;
	--ion-color-whatsapp-contrast-rgb: 255,255,255;
	--ion-color-whatsapp-shade: #25b340;
	--ion-color-whatsapp-tint: #3fd05b;

  /** twitter **/
  --ion-color-twitter: #1da1f2;
  --ion-color-twitter-rgb: 29,161,242;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255,255,255;
  --ion-color-twitter-shade: #1a8ed5;
  --ion-color-twitter-tint: #34aaf3;

  --ion-color-transparentMenu: transparent;
  --ion-color-transparentMenu-rgb: 0,0,0;
  --ion-color-transparentMenu-contrast: #ffffff;
  --ion-color-transparentMenu-contrast-rgb: 255,255,255;
  --ion-color-transparentMenu-shade: transparent;
  --ion-color-transparentMenu-tint: transparent;

  /** always dark **/
  --ion-color-alwaysDark: #111111;
  --ion-color-alwaysDark-rgb: 17, 17, 17;
  --ion-color-alwaysDark-contrast: #ffffff;
  --ion-color-alwaysDark-contrast-rgb: 255, 255, 255;
  --ion-color-alwaysDark-shade: #1e1e1e;
  --ion-color-alwaysDark-tint: #3a3a3a;
}

// Cookie banner
.cc-window {
  text-align: center;
  border: 1px solid var(--ion-border-color);
  &.cc-invisible {
    display: none;
  }
  background: var(--ion-item-background);
  color: var(--ion-text-color);
  .cc-link {
    color: var(--ion-text-color);
  }
  .cc-btn {
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    border: 0;
  }

  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  @-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-whatsapp {
  --ion-color-base: var(--ion-color-whatsapp);
  --ion-color-base-rgb: var(--ion-color-whatsapp-rgb);
  --ion-color-contrast: var(--ion-color-whatsapp-contrast);
  --ion-color-contrast-rgb: var(--ion-color-whatsapp-contrast-rgb);
  --ion-color-shade: var(--ion-color-whatsapp-shade);
  --ion-color-tint: var(--ion-color-whatsapp-tint);
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-transparentMenu {
  --ion-color-base: var(--ion-color-transparentMenu);
  --ion-color-base-rgb: var(--ion-color-transparentMenu-rgb);
  --ion-color-contrast: var(--ion-color-transparentMenu-contrast);
  --ion-color-contrast-rgb: var(--ion-color-transparentMenu-contrast-rgb);
  --ion-color-shade: var(--ion-color-transparentMenu-shade);
  --ion-color-tint: var(--ion-color-transparentMenu-tint);
}

.ion-color-alwaysDark {
  --ion-color-base: var(--ion-color-alwaysDark);
  --ion-color-base-rgb: var(--ion-color-alwaysDark-rgb);
  --ion-color-contrast: var(--ion-color-alwaysDark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-alwaysDark-contrast-rgb);
  --ion-color-shade: var(--ion-color-alwaysDark-shade);
  --ion-color-tint: var(--ion-color-alwaysDark-tint);
}

.ion-color-lighter {
  --ion-color-base: var(--ion-color-lighter);
  --ion-color-base-rgb: var(--ion-color-lighter-rgb);
  --ion-color-contrast: var(--ion-color-lighter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lighter-contrast-rgb);
  --ion-color-shade: var(--ion-color-lighter-shade);
  --ion-color-tint: var(--ion-color-lighter-tint);
}
.ion-color-lighter2 {
  --ion-color-base: var(--ion-color-lighter2);
  --ion-color-base-rgb: var(--ion-color-lighter2-rgb);
  --ion-color-contrast: var(--ion-color-lighter2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lighter2-contrast-rgb);
  --ion-color-shade: var(--ion-color-lighter2-shade);
  --ion-color-tint: var(--ion-color-lighter2-tint);
}


@media (max-width: 1500px) {
	:root {
    ion-grid.largeGrid {
      max-width: 1150px;
    }
  }
}









@-webkit-keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}